import api from "@api";
export default {
    state: {
        isDialogOpen: false,
        isDialog: false,
        settingDto: {},
        dashDefaultValues: [],
        teacherList: [],
        teacherDto: {},
        yearList: [],
        yearDto: {},
        semesterList: [],
        semesterDto: {},
        teacherCols: [
            {
                label: "اسم الإستاذ",
                field: "name",
            },
            {
                label: " رقم الموبايل",
                field: "phoneNumber",
            },
            {
                label: " عدد الكورسات",
                type: "number",
                field: "courseCount",
            },
            {
                label: "تاريخ الإضافة ",
                field: "dateCreated",
            },
            {
                label: "التفاصيل",
                field: "details",
            },
        ],
        yearCols: [
            {
                label: "السنة",
                field: "name",
            },
            {
                label: "التفاصيل",
                field: "details",
            },
        ],
        semesterCols: [
            {
                label: "الفصل",
                field: "name",
            },
            {
                label: "التفاصيل",
                field: "details",
            },
        ],
    },
    mutations: {
        Get_Settings_Rate(state, payload) {
            state.dashDefaultValues = payload;
        },
        Get_Settings_List(state, payload) {
            state.settingDto = payload;
        },
        Get_Settings_Teacher(state, payload) {
            state.teacherList = payload;
        },
        Get_Settings_Years(state, payload) {
            state.yearList = payload;
        },
        Get_Settings_Semester(state, payload) {
            state.semesterList = payload;
        },
        IS_DIALOG_OPEN(state, payload) {
            state.isDialogOpen = payload;
        },
        IS_DIALOG(state, payload) {
            state.isDialog = payload;
        },
        Update_Rate(state, payload) {
            let dto = state.dashDefaultValues.find((ele) => ele.id == payload.id);
            Object.assign(dto, payload);
        },
        Add_Teacher(state, payload) {
            if (state.settingDto.teachers.some(y => y.id == payload.id)) {
                let dto = state.settingDto.teachers.find((ele) => ele.id == payload.id);
                Object.assign(dto, payload);
            }
            else {
                state.settingDto.teachers.unshift(payload);
            }
        },
        Add_Semester(state, payload) {
            if (state.settingDto.semesters.some(y => y.id == payload.id)) {
                let dto = state.settingDto.semesters.find((ele) => ele.id == payload.id);
                Object.assign(dto, payload);
            }
            else {
                state.settingDto.semesters.unshift(payload);
            }
        },
        Add_Year(state, payload) {
            if (state.settingDto.years.some(y => y.id == payload.id)) {
                let dto = state.settingDto.years.find((ele) => ele.id == payload.id);
                Object.assign(dto, payload);
            }
            else {
                state.settingDto.years.unshift(payload);
            }
        },
        Delete_Teacher(state, payload) {
            state.settingDto.teachers = state.settingDto.teachers.filter(
                (el) => !payload.includes(el.id)
            );
        },
        Delete_Semester(state, payload) {
            state.settingDto.semesters = state.settingDto.semesters.filter(
                (el) => !payload.includes(el.id)
            );
        },
        Delete_Year(state, payload) {
            state.settingDto.years = state.settingDto.years.filter(
                (el) => !payload.includes(el.id)
            );
        },
        Set_Year_Dto(state, payload) {
            if (payload) state.yearDto = { ...payload };
            else
                state.yearDto = {
                    name: "",
                };
        },
        Set_Semester_Dto(state, payload) {
            if (payload) state.semesterDto = { ...payload };
            else
                state.semesterDto = {
                    name: "",
                };
        },
        SET_TEACHER_DTO(state, payload) {
            if (payload) state.teacherDto = { ...payload };
            else
                state.teacherDto = {
                    name: "",
                    phoneNumber: "",
                    password: ''
                };
        },
        Reset_Teacher_Dto(state) {
            state.teacherDto = {
                id: "",
                name: "",
                phoneNumber: "",
                password: "",
            };
        },
    },
    actions: {
        getSettings({ commit }) {
            api.get("Setting/Dash/GetSetting", ({ data }) => {
                commit("Get_Settings_List", data);
            });
        },
        getRate({ commit }) {
            api.get("Setting/Dash/GetDefaultValues", ({ data }) => {
                commit("Get_Settings_Rate", data);
            });
        },
        getSettingTeacher({ commit }) {
            api.get("Setting/Dash/GetAllTeachers", ({ data }) => {
                commit("Get_Settings_Teacher", data);
            });
        },
        getSettingYear({ commit }) {
            api.get("Setting/Dash/GetYears", ({ data }) => {
                commit("Get_Settings_Years", data);
            });
        },
        getSettingSemester({ commit }) {
            api.get("Setting/Dash/GetSemesters", ({ data }) => {
                commit("Get_Settings_Semester", data);
            });
        },
        getTeacherName({ commit }) {
            api.get("Setting/Dash/GetTeachersNames", ({ data }) => {
                commit("Get_Teacher_Name", data);
            });
        },
        updateRate({ commit }, payload) {
            api.post(
                "Setting/App/ModifyDefaultValues",
                payload,
                (res) => {
                    commit("Update_Rate", res.data);
                },
                {
                    confirm: "هل تريد بالتأكيد التعديل ",
                    success: "تم التعديل بنجاح",
                    error: "فشل التعديل.. يرجى إعادة المحاولة",
                }
            );
        },
        addTeacher({ commit }, payload) {
            api.post(
                "Setting/Dash/UpsertTeacher",
                payload,
                (res) => {
                    commit("Add_Teacher", res.data);
                    commit('SET_TEACHER_DTO')
                },
                {
                    success: "تم  بنجاح",
                    error: "فشل .. يرجى إعادة المحاولة",
                }
            );
        },
        addSemester({ commit }, payload) {
            api.post(
                "Setting/Dash/UpsertSemester",
                payload,
                (res) => {
                    commit("Add_Semester", res.data);
                },
                {
                    confirm: "هل تريد بالتأكيد التعديل ",
                    success: "تم  بنجاح",
                    error: "فشل .. يرجى إعادة المحاولة",
                }
            );
        },
        addYears({ commit }, payload) {
            api.post(
                "Setting/Dash/UpsertYear",
                payload,
                (res) => {
                    commit("Add_Year", res.data);
                },
                {
                    confirm: "هل تريد بالتأكيد التعديل ",
                    success: "تم  بنجاح",
                    error: "فشل .. يرجى إعادة المحاولة",
                }
            );
        },
        deleteTeacher({ commit }, ids) {
            api.delete(
                "Setting/Dash/DeleteTeachers",
                () => {
                    commit("Delete_Teacher", ids);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف الاستاذ  المحددة",
                    success: "تم حذف الاستاذ  بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                ids
            );
        },
        deleteSemester({ commit }, ids) {
            api.delete(
                "Setting/Dash/DeleteSemesters",
                () => {
                    commit("Delete_Semester", ids);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف الفصل  المحددة",
                    success: "تم حذف الفصل  بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                ids
            );
        },
        deleteYear({ commit }, ids) {
            api.delete(
                "Setting/Dash/DeleteYears",
                () => {
                    commit("Delete_Year", ids);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف السنة  المحددة",
                    success: "تم حذف السنة  بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                ids
            );
        },
    },
};
